<template>
   <!-- Start Upperbar -->
   <div id="upper-bar" class="upper-bar">
      <div class="container">
         <div class="row">
            <div class="col-12">
               <div class="inner-bar">
                  <div class="row">
                     <div class="col-lg-8 col-12 text-center text-lg-left">
                        <ul class="contact-bar list-unstyled">
                           <li>
                              <a href="mailto:sis@phoenix-Heart.yoga">
                              <i class="fa fa-envelope"></i>
                              E-Mail
                              </a>
                           </li>
                           <li>
                              <a href="https://wa.me/447762162224">
                              <i class="fas fa-phone-alt"></i>
                              WhatsApp
                              </a>
                           </li>
                           <li>
                              <a href="https://goo.gl/maps/VrGEpC9y2hPhNVfc6">
                              <i class="fa fa-map-marker"></i>
                              14 Lord Street West, Blackburn, United Kingdom
                           </a>
                           </li>
                        </ul>
                     </div>
                     <div class="col-lg-4 col-12 text-center text-lg-right">
                        <ul class="social-media-bar list-unstyled">
                           <li>
                              <a href="https://www.facebook.com/Phoenix.Heart.Yoga.with.Sis" target="_blank">
                              <i class="fab fa-facebook-f"></i>
                              </a>
                           </li>
                           <li>
                              <a href="https://www.google.com/search?q=Phoenix+Heart+Yoga+with+Sis&stick=H4sIAAAAAAAA_-NgU1I1qDCxME-yTDNIMrdMSzI1STO0MqhISzM1NE5KMTJNNbc0NzQ1W8QqE5CRn5qXWaEQlFmcmZeuEJmfnqhQnlmSoRCcWQwAJ7aey0gAAAA&hl=en-GB&mat=CSuvgVw-J_CpElcB8pgkaFXFYVc53hpqCNGaL4utIIpVg5rPGcyEQFnmcxqmpo4_FhaYa5DY6k75R9HwuDHoqlnKdf0FkYerAy2be8_wclLaXcX1Y9bmWkZmUCvQ4RoX24E&authuser=0&ved=2ahUKEwi5vuXJ4Mr8AhWQd8AKHU-ECf0Q-MgIKAR6BAgYEB4" target="_blank">
                              <i class="fab fa-google"></i>
                              </a>
                           </li>
                        </ul>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
   <!-- End Upperbar -->
</template>

<script>
export default {
};
</script>

<style lang="scss" scoped></style>