<template>
  <div class="wrraper">
    <app-bar-top></app-bar-top>
    <app-header-inner></app-header-inner>
    <app-page-Title
      :heading="heading"
      :sousHeading="sousHeading"
    ></app-page-Title>
    <app-class-schedle></app-class-schedle>
    <app-newsLetter :class="'area'"></app-newsLetter>
    <app-footer> </app-footer>
  </div>
</template>

<script>
import UpperBar from "../../components/upperBar.vue";
import headerInner from "../../components/headerInner";
import pageTitle from "../../components/widget/pageTitle.vue";
import classSchedle from "../../components/schedule.vue";
import footer from "../../components/footer.vue";
export default {
  data: function () {
    return {
      heading: "Class Schedule",
      sousHeading: "Class Schedule",
    };
  },
  components: {
    "app-bar-top": UpperBar,
    "app-header-inner": headerInner,
    "app-page-Title": pageTitle,
    "app-class-schedle": classSchedle,
    "app-footer": footer,
  },
};
</script>

<style lang="scss" scoped></style>