<template>
  <!-- Start Footer-->
  <footer class="footer">
    <div class="footer-top">
      <i class="flaticon-lotus"></i>
      <div class="container">
        <div class="row">
          <div class="col-lg-3 col-sm-6 col-12 footer-menu">
            <div class="footer-logo">
              <router-link class="my-logo" to="/">
                <i class="flaticon-chakra"></i>
                Phoenix Heart Yoga
              </router-link>
            </div>
            <ul class="list-unstyled">
              
              <li>                  <a href="https://goo.gl/maps/VrGEpC9y2hPhNVfc6">
                  Blackburn, UK
                  </a></li>
              <li>
                  <a href="mailto:sis@phoenix-Heart.yoga">
                  E-Mail
                  </a>
              </li>
              <li>
                  <a href="https://wa.me/447762162224">
                  WhatsApp
                  </a>
              </li>
            </ul>
            <ul class="list-unstyled social-media">
              <li>
                <a href="https://www.facebook.com/Phoenix.Heart.Yoga.with.Sis" target="_blank">
                  <i class="fab fa-facebook-f"></i>
                </a>
              </li>
              <li>
                <a href="https://www.google.com/search?q=Phoenix+Heart+Yoga+with+Sis&stick=H4sIAAAAAAAA_-NgU1I1qDCxME-yTDNIMrdMSzI1STO0MqhISzM1NE5KMTJNNbc0NzQ1W8QqE5CRn5qXWaEQlFmcmZeuEJmfnqhQnlmSoRCcWQwAJ7aey0gAAAA&hl=en-GB&mat=CSuvgVw-J_CpElcB8pgkaFXFYVc53hpqCNGaL4utIIpVg5rPGcyEQFnmcxqmpo4_FhaYa5DY6k75R9HwuDHoqlnKdf0FkYerAy2be8_wclLaXcX1Y9bmWkZmUCvQ4RoX24E&authuser=0&ved=2ahUKEwi5vuXJ4Mr8AhWQd8AKHU-ECf0Q-MgIKAR6BAgYEB4" target="_blank">
                  <i class="fab fa-google"></i>
                </a>
              </li>
             
            </ul>
          </div>
          <div class="col-lg-3 col-sm-6 col-12 footer-menu">
            <div class="footer-item">
              <h4>Company</h4>
              <ul class="list-unstyled">
                <router-link to="/" tag="li"><a target="_blank">Home</a></router-link>
                <router-link to="/about-us" tag="li"><a target="_blank">About us</a></router-link>
                <router-link to="/services" tag="li"><a target="_blank">Services</a></router-link>
              </ul>
            </div>
          </div>
          <div class="col-lg-3 col-sm-6 col-12 footer-menu">
            <div class="footer-item">
              <h4>Menu</h4>
              <ul class="list-unstyled">
                <router-link to="/schedule" tag="li"><a target="_blank">Schedule</a></router-link>
                <router-link to="/pricing" tag="li"><a target="_blank">Pricing Plans</a></router-link>
                <router-link to="/contact-us" tag="li"><a target="_blank">Contact</a></router-link>
              </ul>
            </div>
          </div>
          <div class="col-lg-3 col-sm-6 col-12 footer-menu">
            <div class="footer-item">
              <h4>Support</h4>
              <ul class="list-unstyled">
                <router-link to="/faq" tag="li"><a target="_blank">FAQ</a></router-link>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-bottom text-center">
      <div class="copyright">
        <p>&copy; All rights reserved</p>
        <p><span>Phoenix Heart Yoga with Sis</span></p>
        <p>2023</p>
      </div>
    </div>
  </footer>
  <!-- End Footer -->
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>